import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';


const ErrorDialog = (props) => {

    const { open, message, success, handleClose, newForm } = props;


    const uuid = uuidv4();

    const Navigate = useNavigate();
    const RouteNavigate = () => {
        Navigate(`/formsregister/${uuid}`, { replace: true });
        handleClose();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">

                {

                    success ?
                        <Box sx={{
                            display:'flex',
                            alignItems:'center',
                            width:'100%'
                        }}>
                            <Typography color='success' variant='h5'>
                                Completado   
                            </Typography>

                            <CheckCircleIcon color='success' fontSize='large' sx={{m:1}} />

                        </Box> :
                        <Box sx={{
                            display:'flex',
                            alignItems:'center',
                            width:'100%'
                        }}
                        >
                            <Typography color='error' variant='h5'>
                                Error   
                            </Typography>
                            
                            <ErrorIcon color='error' fontSize='large' sx={{m:1}} />
                        </Box>

                }

            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                
                {
                    newForm ? 
                    <Button onClick={RouteNavigate}> Nueva encuesta </Button>
                    : <></>
                }

                <Button onClick={handleClose}>Cerrar</Button>
            </DialogActions>
        </Dialog>
    )
}

ErrorDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    message: PropTypes.string,
    success: PropTypes.bool.isRequired,
    newForm: PropTypes.bool,
};

export default ErrorDialog
