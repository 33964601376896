import React from 'react'
import logo from '../../img/logo-aw-w.png'
import { Box, Dialog, DialogContent, LinearProgress } from '@mui/material';
import PropTypes from 'prop-types';

const Progress = (props) => {

    return (
        <Dialog open={props.open} >
            <DialogContent sx={{
                width: 150,
            }}>
                <Box sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    padding: 2,
                    position: 'relative'
                }}>
                    <img src={logo} alt='progres1' style={{ width: '90%' }} />
                </Box>
                <LinearProgress />
            </DialogContent>
        </Dialog>
    )
}

Progress.propTypes = {
    open: PropTypes.bool.isRequired,
};

export default Progress
