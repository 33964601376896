import React from 'react';
import './App.css';
import Forms from './component/Forms';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import FormsFinished from './component/FormsFinished';
import FormsWelcome from './component/FormsWelcome';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // Tu archivo i18n.js


function App() {
  return (
    <React.Fragment>
      <I18nextProvider i18n={i18n}>
        <HashRouter>
          <div style={{
            backgroundImage: 'linear-gradient(60deg, #3d3393 0%, #2b76b9 37%, #2cacd1 65%, #35eb93 100%)',
            padding: '15px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>

            <Routes>

              <Route path='/' element={<FormsWelcome />}> </Route>
              <Route path='/formsregister' element={<Navigate to={'/'} ></Navigate>}></Route>
              <Route path='/formsfinished' element={<Navigate to={'/'} ></Navigate>}></Route>
              <Route path='/formsregister/:id' element={<Forms />}> </Route>
              <Route path='/formsfinished/:id' element={<FormsFinished />} ></Route>

            </Routes>

          </div>
        </HashRouter>
      </I18nextProvider>
    </React.Fragment>
  );
}

export default App;
