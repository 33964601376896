// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    greeting: 'Hello',
                    activity: 'What activity did you enjoy today?',
                    aquisicion: 'How did you purchase your Aquaworld activities?',
                    Aoption1: 'Travel Agent',
                    Aoption2: 'Hotel pool side Aquaworld Rep',
                    Aoption3: 'Front Desk Aquaworld Rep',
                    Aoption4: 'Online Site',
                    Aoption5: 'Other',

                    YES: 'YES',
                    NO: 'NO',

                    Information: 'Do you consider the information provided by the sales person to be adequate?',
                    Comment: 'Comment',

                    questions: 'Please rate the following',
                    bad: 'Bad',
                    average: 'Average',
                    good: 'Good',
                    staff: 'Staff(attitude and service)',
                    facilities: 'Facilities',
                    boats: 'Boats',
                    clean: 'Cleanliness',
                    food: 'Food & beverages',

                    recomed: 'Would you recommend us?',
                    commentsugestions: 'Comments or suggestions',

                    email: 'Email',
                    name:'Name',
                    age:'Age',
                    country:'Country',
                    hotel:'Hotel',

                    promotion:'Would you like to receive information and promotions via email?',

                    rating:'Rate us',
                    send: 'SEND',

                    war: 'Never send passwords through Forms',

                    errorsend1: 'Check all your answers, you are missing some.',
                    errorsend2: 'This field is required.',
                    errorsend3: 'Select an option.',
                    errorsend4: 'Please select one option for each question.',

                    title: 'Customer Satisfaction Survey',
                    subtitle: 'Help us improve our service by answering the following questions.',
                    finally: 'Thank you for your time, we look forward to seeing you again!',

                    welcome:'We invite you to answer our satisfaction survey.',
                    welcomebutton:'START NOW',

                    finished: 'Thank you very much! Your opinion helps us to improve.',
                    finishedbutton:'New survey',

                    es: 'Spanish',
                    en:'English',
                    // Resto de las traducciones en inglés
                }
            },
            es: {
                translation: {
                    greeting: 'Hola',
                    activity: '¿Qué actividad disfrutó el día de hoy?',
                    aquisicion: '¿Cómo adquirió las actividades de Aquaworld?',
                    Aoption1: 'Agente de viajes',
                    Aoption2: 'Módulo Aquaworld en hotel',
                    Aoption3: 'Recepción en Aquaworld',
                    Aoption4: 'Página web',
                    Aoption5: 'Otro',

                    YES: 'SI',
                    NO: 'NO',

                    Information: '¿Considera adecuada la información que el vendedor le proporcionó?',
                    Comment: 'Comentarios',

                    questions: 'Por favor califique lo siguiente',
                    bad: 'Malo',
                    average: 'Regular',
                    good: 'Bueno',
                    staff: 'Staff(actitud y atención)',
                    facilities: 'Instalaciones',
                    boats: 'Embarcaciones',
                    clean: 'Limpieza',
                    food: 'Comida y bebidas',

                    recomed: '¿Nos recomendaría?',
                    commentsugestions: 'Comentarios o sugerencias',

                    email: 'Correo',
                    name:'Nombre',
                    age:'Edad',
                    country:'País',
                    hotel:'Hotel',

                    promotion:'¿Le gustaría recibir nuestras promociones vía mail?',
                    rating:'Calificanos',
                    send: 'ENVIAR',

                    war: 'Nunca envíes contraseñas a través de Formularios',

                    errorsend1: 'Verifica todas tus respuestas, te faltan algunas.',
                    errorsend2: 'Este campo es obligatorio.',
                    errorsend3: 'Seleccione una opción.',
                    errorsend4: 'Por favor seleccione una opción para cada pregunta.',

                    title: 'Encuesta de satisfacción',
                    subtitle: 'Ayudenos a mejorar nuestro servicio, respondiendo las siguientes preguntas.',
                    finally: '¡Gracias por su tiempo, esperamos verlo nuevamente!',

                    welcome:'Te invitamos a contestar nuestra encuesta de satisfacción.',
                    welcomebutton:'EMPEZAR AHORA',

                    finished: '¡Muchas Gracias!, Tu opinión nos ayuda a mejorar.',
                    finishedbutton:'Nueva encuesta',

                    es: 'Español',
                    en:'Inglés',
                    // Resto de las traducciones en español  
                }
            },
            // Agrega más idiomas aquí
        },
        lng: 'en', // Idioma predeterminado
        fallbackLng: 'en', // Idioma de respaldo
        interpolation: {
            escapeValue: false // Permite usar HTML en las traducciones
        }
    });

export default i18n;