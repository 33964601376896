import React from 'react'
import { Grid, Typography } from '@mui/material';
import image from '../../img/landing.jpg'
import logo from '../../img/logo-white.svg'
import ButtonLang from '../Usage/ButtonLang';
import { useTranslation } from 'react-i18next';

const InputsTitle = () => {
    const { t } = useTranslation();
    return (
        <Grid container
            sx={{
                padding: 2,
                backgroundImage: `linear-gradient(to right, rgb(36, 57, 73, 0.8) 0%, rgb(81, 127, 164, 0.8) 100%), url(${image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: 2,
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                display: { xs: 'flex' },
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            }}
        >
            <Grid item xs={12}>
                <ButtonLang />
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <img src={logo}
                    style={{
                        width: '200px'
                    }}
                    alt='logo'
                />
            </Grid>

            <Grid item xs={12} sx={{ textAlign: 'center' }} >
                <Typography variant='h5' sx={{ color: 'white', fontWeight: 'bold' }} >
                    {t('title')}
                </Typography>
            </Grid>

            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography variant='body2' sx={{ color: 'white', fontWeight: 'bold' }}  >
                    {t('subtitle')}
                </Typography>
            </Grid>

        </Grid>
    )
}

export default InputsTitle
