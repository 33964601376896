import React, { useState, useEffect } from 'react'
import { Box, Grid } from '@mui/material';
import Inputs from './FormsInputs/Inputs';
import InputsTitle from './FormsInputs/InputsTitle';
import InputsFooter from './FormsInputs/InputsFooter';
import axios from 'axios';
import Progress from './Usage/Progress';
import ErrorDialog from './Usage/ErrorDialog';


const Forms = () => {

    const [openMessage, setOpenMessage] = useState(false);
    const [success, setSuccess] = useState(true);
    const handleClose = () => {
        setOpenMessage(false);
    };

    const [dataServices, setData] = useState([]);
    const [openProgress, setOpenProgress] = useState(false);

    useEffect(() => {
        // Realizar la solicitud GET a la API
        setOpenProgress(true);
        axios.get(process.env.REACT_APP_API_FINDACTIVITY)
            .then(response => {
                // Actualizar el estado con los datos de la respuesta
                setData(response.data);
                setOpenProgress(false);
                setSuccess(true);
            })
            .catch(error => {
                // Manejar el error en caso de que ocurra
                console.error(error);
                setOpenProgress(false);
                setOpenMessage(true);
                setSuccess(false);
            });
    }, []);

    console.log(dataServices);

    return (
        <Box component={'div'}
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap'
            }}
        >

            <Progress
                open={openProgress}
            />

            {/* Grid principal que contiene todo el formulario */}
            <Grid container
                sx={{
                    gap: 2,
                    width: 800,
                }}
            >


                <ErrorDialog
                    open={openMessage}
                    handleClose={handleClose}
                    message={'Error inesperado'}
                    success={success}
                />

                {/* Grid que contiene el titulo */}
                <InputsTitle />

                {/*cuerpo del formulario*/}
                <Inputs 
                    dataServices = { dataServices }
                />


                {/* Grid que contiene el footer del formulario */}
                <InputsFooter />


            </Grid>

        </Box>
    )
}

export default Forms
