import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { Divider, Grid, Typography, Button, FormHelperText } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import SendIcon from '@mui/icons-material/Send';
import PropTypes from 'prop-types';
import Progress from '../Usage/Progress';
import ErrorDialog from '../Usage/ErrorDialog';
import axios from 'axios';
import { Autocomplete } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Inputs = (props) => {
    const { t } = useTranslation();

    const { id } = useParams();

    const Navigate = useNavigate();
    const RouteNavigate = (path) => {
        Navigate(path);
    }

    const [newForm, setNewForm] = useState(false);

    const [openMessage, setOpenMessage] = useState(false);
    const [success, setSuccess] = useState(true);
    const handleClose = () => {
        setOpenMessage(false);
    };
    const [openProgress, setOpenProgress] = useState(false);
    const [message, setMessage] = useState('');

    //::::::::::::::::::::::::::::::::::: DEFINIMOS LOS ERRORES PARA VALIDACION :::::::::::::::::::::::::::::::::::
    const [errorActivity, setActivity] = React.useState(false);
    const [helperTextActivity, setHelperTextActivity] = React.useState(null);

    const [errorForma_adquirio, setForma_adquirio] = React.useState(false);
    const [helperTextForma_adquirio, setHelperTextForma_adquirio] = React.useState(null);

    const [errorInformation_vendedor, setInformation_vendedor] = React.useState(false);
    const [helperTextInformation_vendedor, setHelperTextInformation_vendedor] = React.useState(null);

    const [errorCalificacion, setCalificacion] = React.useState(false);
    const [helperTextCalificacion, setHelperTextCalificacion] = React.useState(null);

    const [errorRecomendaria, setRecomendaria] = React.useState(false);
    const [helperTextRecomendaria, setHelperTextRecomendaria] = React.useState(null);

    const [datosPersonales, setDatosPersonales] = React.useState({
        Nombre: null,
        //Email: null,
        Edad: null,
        Promociones: null,
        Rating: null,
    })


    //::::::::::::::::::::::::::::::::::: DEFINIMOS LOS ERRORES PARA VALIDACION :::::::::::::::::::::::::::::::::::

    const { dataServices } = props;

    const [formData, setFormData] = React.useState({
        Uuid: id,
        Activity: null,
    });

    //Funcion que nos permite setear los datos del formulario en un json
    function handleChange(event) {

        const { value, name } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    function handleChanged(event, value) {
        if (value){
            setFormData(prevData => ({
                ...prevData,
                Activity: value.clave,
            }));
        } else {
            setFormData(prevData => ({
                ...prevData,
                Activity: null,
            }));
        }
    };

    //funcion que valida los datos y que manda a la api el formulario
    function handleSubmit(event) {

        setOpenProgress(true);
        event.preventDefault();

        let isValid = true;

        if (!formData.Activity) {
            setActivity(true);
            setHelperTextActivity(t('errorsend2'))
            isValid = false
        } else {
            setActivity(false);
            setHelperTextActivity(null)
        }

        if (!formData.Forma_adquirio) {
            setForma_adquirio(true);
            setHelperTextForma_adquirio(t('errorsend3'))
            isValid = false
        } else {
            setForma_adquirio(false);
            setHelperTextForma_adquirio(null)
        }

        if (!formData.Information_vendedor) {
            setInformation_vendedor(true);
            setHelperTextInformation_vendedor(t('errorsend3'))
            isValid = false
        } else {
            setInformation_vendedor(false);
            setHelperTextInformation_vendedor(null)
        }

        if (!formData.Comida_sa || !formData.Limpieza_sa || !formData.Embarcaciones_sa || !formData.Instalaciones_sa || !formData.Staff_sa) {
            setCalificacion(true);
            setHelperTextCalificacion(t('errorsend4'))
            isValid = false
        } else {
            setCalificacion(false);
            setHelperTextCalificacion(null)
        }

        if (!formData.Recomendaria) {
            setRecomendaria(true);
            setHelperTextRecomendaria(t('errorsend3'))
            isValid = false
        } else {
            setRecomendaria(false);
            setHelperTextRecomendaria(null)
        }

        if (!formData.Names) {
            setDatosPersonales(prevState => ({
                ...prevState,
                Nombre: true
            }));
            isValid = false;
        } else {
            setDatosPersonales(prevState => ({
                ...prevState,
                Nombre: false
            }));
        }

        //if (!formData.Email) {
        //    setDatosPersonales(prevState => ({
        //        ...prevState,
        //        Email: true
        //    }));
        //    isValid = false;
        //} else {
        //    setDatosPersonales(prevState => ({
        //        ...prevState,
        //        Email: false
        //    }));
        //}

        if (!formData.Edad) {
            setDatosPersonales(prevState => ({
                ...prevState,
                Edad: true
            }));
            isValid = false;
        } else {
            setDatosPersonales(prevState => ({
                ...prevState,
                Edad: false
            }));
        }

        if (!formData.Promociones) {
            setDatosPersonales(prevState => ({
                ...prevState,
                Promociones: true
            }));
            isValid = false;
        } else {
            setDatosPersonales(prevState => ({
                ...prevState,
                Promociones: null
            }));
        }

        if (!formData.Rating) {
            setDatosPersonales(prevState => ({
                ...prevState,
                Rating: true
            }));
            isValid = false;
        } else {
            setDatosPersonales(prevState => ({
                ...prevState,
                Rating: null
            }));
        }

        if (isValid) {
            formData.Uuid = id;
            postData();
        } else {
            setMessage(t('errorsend1'))
            setOpenProgress(false);
            setOpenMessage(true);
            setSuccess(false);
        }
    }

    //enviamos la informacion a la api.
    const postData = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_CREATEFORM, formData);
            // Puedes trabajar con la respuesta del servidor aquí
            setOpenProgress(false);
            console.log(formData);

            if (response.data.code !== '999') {
                setMessage(response.data.message);
                setNewForm(true);
                setOpenProgress(false);
                setOpenMessage(true);
                setSuccess(false);
            } else {
                RouteNavigate(`/formsfinished/${id}`);
            }

        } catch (error) {
            // Manejo de errores en caso de que la solicitud falle
            console.error(error);
            setMessage('Error inesperado, no se pudó envia su respuesta');
            setOpenProgress(false);
            setOpenMessage(true);
            setSuccess(false);
        }
    };

    //Emoticon personalizados para regular, malo, bueno
    function Good(props) {
        return (
            <Radio
                disableRipple
                color="default"
                checkedIcon={<SentimentSatisfiedAltIcon color='success' sx={{ fontSize: '3rem' }} />}
                icon={<SentimentSatisfiedAltIcon sx={{ fontSize: '3rem' }} />}
                {...props}
            />
        );
    }

    function Average(props) {
        return (
            <Radio
                disableRipple
                color="default"
                checkedIcon={<SentimentNeutralIcon color='warning' sx={{ fontSize: '3rem' }} />}
                icon={<SentimentNeutralIcon sx={{ fontSize: '3rem' }} />}
                {...props}
            />
        );
    }

    function Bad(props) {
        return (
            <Radio
                disableRipple
                color="default"
                checkedIcon={<SentimentVeryDissatisfiedIcon color='error' sx={{ fontSize: '3rem' }} />}
                icon={<SentimentVeryDissatisfiedIcon sx={{ fontSize: '3rem' }} />}
                {...props}
            />
        );
    }

    //Props y funciones para el autocomplete//
    const defaultProps = {
        options: dataServices,
        getOptionLabel: (option) => option.title,
    };
    //Props y funciones para el autocomplete//


    //renderizado de etiquetas html y material ui
    return (
        <form onSubmit={handleSubmit}>
            <Grid container
                sx={{
                    gap: 2,
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >

                <Progress
                    open={openProgress}
                />

                <ErrorDialog
                    open={openMessage}
                    handleClose={handleClose}
                    message={message}
                    success={success}
                    newForm={newForm}
                />

                {/* actividad que realizo */}
                <Grid item xs={12} sx={{
                    padding: 2,
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    borderRadius: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    backdropFilter: 'blur(2px)',
                }}>

                    <FormControl variant="standard" fullWidth>
                        <Autocomplete
                            {...defaultProps}
                            id="Activity"
                            disableCloseOnSelect
                            renderInput={(params) => (
                                <TextField {...params} label={t('activity')} variant="standard" />
                            )}
                            onChange=
                            {(event, newValue) => {
                                handleChanged(event, newValue);
                            }}
                        />
                        <FormHelperText error={errorActivity} >{helperTextActivity}</FormHelperText>
                    </FormControl>
                </Grid>

                <Divider sx={{ width: '100%' }}></Divider>

                {/* Como adquirió las actividades */}
                <Grid item xs={12} sx={{
                    padding: 2, boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    borderRadius: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    backdropFilter: 'blur(2px)',
                }} >
                    <FormControl fullWidth error={errorForma_adquirio}>
                        <FormLabel id="demo-radio-buttons-group-label"> {t('aquisicion')} </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            id='forma_adquirio'
                            name='Forma_adquirio'
                            onChange={handleChange}
                            row
                        >
                            <FormControlLabel value="Agente de Viajes" control={<Radio />} label={t('Aoption1')} />
                            <FormControlLabel value="Módulo Aquaworld en hotel" control={<Radio />} label={t('Aoption2')} />
                            <FormControlLabel value="Recepción en Aquaworld" control={<Radio />} label={t('Aoption3')} />
                            <FormControlLabel value="Página web" control={<Radio />} label={t('Aoption4')} />
                            <FormControlLabel value="otro" control={<Radio />} label={t('Aoption5')} />
                        </RadioGroup>
                    </FormControl>
                    <FormHelperText error={errorForma_adquirio} >{helperTextForma_adquirio}</FormHelperText>
                </Grid>

                <Divider sx={{ width: '100%' }}></Divider>

                {/* ¿Considera adecuada la información que el vendedor le proporcionó? */}
                <Grid item xs={12} sx={{
                    padding: 2, boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    borderRadius: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    backdropFilter: 'blur(2px)',
                }} >
                    <FormControl fullWidth error={errorInformation_vendedor} >
                        <FormLabel id="demo-radio-buttons-group-label"> {t('Information')} </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            id='information_vendedor'
                            name="Information_vendedor"
                            onChange={handleChange}
                            row
                        >
                            <FormControlLabel value={1} control={<Radio />} label={t('YES')} />
                            <FormControlLabel value={0} control={<Radio />} label={t('NO')} />
                        </RadioGroup>
                    </FormControl>
                    <FormHelperText error={errorInformation_vendedor}> {helperTextInformation_vendedor}</FormHelperText>

                    <TextField
                        id="coment_information"
                        label={t('Comment')}
                        name='Coment_information'
                        value={formData.Coment_information || ''}
                        onChange={handleChange}
                        variant="standard"
                        fullWidth
                        multiline
                        rows={2}
                    />
                </Grid>

                <Divider sx={{ width: '100%' }}></Divider>

                {/* Por favor califique lo siguiente */}
                <Grid item xs={12} sx={{
                    padding: 2, boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    borderRadius: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    backdropFilter: 'blur(2px)',
                }} >

                    <FormLabel id="demo-customized-radios" error={errorCalificacion}> {t('questions')} :</FormLabel>

                    <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                            <SentimentVeryDissatisfiedIcon color='error' fontSize='medium' sx={{ marginRight: 0.5 }} />
                            <FormLabel id="demo-customized-radios"> {t('bad')} </FormLabel>
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                            <SentimentNeutralIcon color='warning' fontSize='medium' sx={{ marginRight: 0.5 }} />
                            <FormLabel id="demo-customized-radios"> {t('average')} </FormLabel>
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                            <SentimentSatisfiedAltIcon color='success' fontSize='medium' sx={{ marginRight: 0.5 }} />
                            <FormLabel id="demo-customized-radios"> {t('good')} </FormLabel>
                        </Grid>
                    </Grid>

                    <FormControl fullWidth sx={{ marginTop: 2 }}>
                        <FormLabel id="demo-radio-buttons-group-label"> {t('staff')} </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="Staff_sa"
                            id='staff_sa'
                            onChange={handleChange}
                            row
                            aria-required
                        >
                            <FormControlLabel value="malo" control={<Bad />} label="" />
                            <FormControlLabel value="regular" control={<Average />} label="" />
                            <FormControlLabel value="bueno" control={<Good />} label="" />
                        </RadioGroup>
                    </FormControl>

                    <Divider sx={{ width: '90%' }}></Divider>

                    <FormControl fullWidth >
                        <FormLabel id="demo-radio-buttons-group-label"> {t('facilities')} </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="Instalaciones_sa"
                            id='Instalaciones_sa'
                            onChange={handleChange}
                            row
                            aria-required
                        >
                            <FormControlLabel value="malo" control={<Bad />} label="" />
                            <FormControlLabel value="regular" control={<Average />} label="" />
                            <FormControlLabel value="bueno" control={<Good />} label="" />
                        </RadioGroup>
                    </FormControl>

                    <Divider sx={{ width: '90%' }}></Divider>

                    <FormControl fullWidth >
                        <FormLabel id="demo-radio-buttons-group-label"> {t('boats')} </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="Embarcaciones_sa"
                            id='embarcaciones_sa'
                            onChange={handleChange}
                            row
                            aria-required
                        >
                            <FormControlLabel value="malo" control={<Bad />} label="" />
                            <FormControlLabel value="regular" control={<Average />} label="" />
                            <FormControlLabel value="bueno" control={<Good />} label="" />
                        </RadioGroup>
                    </FormControl>

                    <Divider sx={{ width: '90%' }}></Divider>

                    <FormControl fullWidth >
                        <FormLabel id="demo-radio-buttons-group-label"> {t('clean')} </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="Limpieza_sa"
                            id='limpieza_sa'
                            onChange={handleChange}
                            row
                            aria-required
                        >
                            <FormControlLabel value="malo" control={<Bad />} label="" />
                            <FormControlLabel value="regular" control={<Average />} label="" />
                            <FormControlLabel value="bueno" control={<Good />} label="" />
                        </RadioGroup>
                    </FormControl>

                    <Divider sx={{ width: '90%' }}></Divider>

                    <FormControl fullWidth >
                        <FormLabel id="demo-radio-buttons-group-label"> {t('food')} </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="Comida_sa"
                            id='comida_sa'
                            onChange={handleChange}
                            row
                            aria-required
                        >
                            <FormControlLabel value="malo" control={<Bad />} label="" />
                            <FormControlLabel value="regular" control={<Average />} label="" />
                            <FormControlLabel value="bueno" control={<Good />} label="" />
                            <FormControlLabel value="noaplica" control={<Radio />} label="N/A" />

                        </RadioGroup>
                    </FormControl>

                    <FormHelperText error={errorCalificacion}> {helperTextCalificacion}</FormHelperText>
                </Grid>

                <Divider sx={{ width: '100%' }}></Divider>

                {/*
                    ¿Nos recomendaría?
                    */}
                <Grid item xs={12} sx={{
                    padding: 2, boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    borderRadius: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    backdropFilter: 'blur(2px)',
                }} >
                    <FormControl fullWidth error={errorRecomendaria}>
                        <FormLabel id="demo-radio-buttons-group-label"> {t('recomed')} </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="Recomendaria"
                            id='recomendaria'
                            onChange={handleChange}
                            row
                            aria-required
                        >
                            <FormControlLabel value="1" control={<Radio />} label={t('YES')} />
                            <FormControlLabel value="0" control={<Radio />} label={t('NO')} />
                        </RadioGroup>
                    </FormControl>
                    <FormHelperText error={errorRecomendaria}> {helperTextRecomendaria}</FormHelperText>

                    <TextField
                        id="sugerencias"
                        name='Sugerencias'
                        value={formData.Sugerencias || ''}
                        onChange={handleChange}
                        label={t('commentsugestions')}
                        variant="standard"
                        fullWidth
                        multiline
                        rows={3} />
                </Grid>

                <Divider sx={{ width: '100%' }}></Divider>

                {/*
                    Datos personales
                    */}
                <Grid item xs={12} sx={{
                    display: 'flex',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    borderRadius: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    backdropFilter: 'blur(2px)',
                }}>

                    <Grid container
                        spacing={2}
                        sx={{ padding: 2 }}
                    >

                        <Grid item xs={12}>
                            <TextField
                                id="names"
                                name='Names'
                                value={formData.Names || ''}
                                onChange={handleChange}
                                label={t('name')}
                                variant="standard"
                                fullWidth
                                error={datosPersonales.Nombre}
                                helperText={datosPersonales.Nombre ? t('errorsend2') : ''}
                            />
                        </Grid >

                        <Grid item xs={12} sm={8}>
                            <TextField
                                id="email"
                                name='Email'
                                value={formData.Email || ''}
                                onChange={handleChange}
                                label={t('email')}
                                variant="standard"
                                type='email'
                                fullWidth
                                //error={datosPersonales.Email}
                                //helperText={datosPersonales.Email ? t('errorsend2') : ''}
                            />
                        </Grid>

                        <Grid item xs={6} sm={4}>
                            <TextField
                                id="edad"
                                name='Edad'
                                value={formData.Edad || ''}
                                onChange={handleChange}
                                label={t('age')}
                                type='number'
                                variant="standard"
                                fullWidth
                                error={datosPersonales.Edad}
                                helperText={datosPersonales.Edad ? t('errorsend2') : ''}
                            />
                        </Grid>

                        <Grid item xs={6} >
                            <TextField
                                id="pais"
                                name='Pais'
                                value={formData.Pais || ''}
                                onChange={handleChange}
                                label={t('country')}
                                variant="standard"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                id="hotel"
                                name='Hotel'
                                value={formData.Hotel || ''}
                                onChange={handleChange}
                                label={t('hotel')}
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                </Grid>

                <Divider sx={{ width: '100%' }}></Divider>

                {/* Aceptacion promociones vía mail */}
                <Grid item xs={12} sx={{
                    padding: 2,
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    borderRadius: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    backdropFilter: 'blur(2px)',
                }} >
                    <FormControl fullWidth error={datosPersonales.Promociones}>
                        <FormLabel id="demo-radio-buttons-group-label"> {t('promotion')} </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            id='promociones'
                            name="Promociones"
                            row
                            onChange={handleChange}
                        >
                            <FormControlLabel value="1" control={<Radio />} label={t('YES')} />
                            <FormControlLabel value="0" control={<Radio />} label={t('NO')} />
                        </RadioGroup>
                    </FormControl>
                    <FormHelperText error={datosPersonales.Promociones}> {datosPersonales.Promociones ? t('errorsend3') : ''} </FormHelperText>
                </Grid>

                <Divider sx={{ width: '100%' }}></Divider>

                {/*
                    Calificanos
                    */}
                <Grid item xs={12} sx={{
                    padding: 2,
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    borderRadius: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    backdropFilter: 'blur(2px)',
                }} >
                    <Typography component="legend"> {t('rating')} </Typography>
                    <Stack spacing={1}>
                        <Rating
                            name="Rating"
                            id='rating'
                            defaultValue={0}
                            size="large"
                            onChange={handleChange}
                        />
                    </Stack>
                    <FormHelperText error={datosPersonales.Promociones}> {datosPersonales.Rating ? t('errorsend3') : ''} </FormHelperText>

                </Grid>

                {/*
                    Botones
                    */}
                <Grid item xs={12}>

                    <Button variant='contained' color='success' size='large' endIcon={<SendIcon />} type="submit" >
                        {t('send')}
                    </Button>

                </Grid>

                <Grid item xs={12}>
                    <Typography variant='body2' color='white'>
                        {t('war')}
                    </Typography>
                </Grid>


            </Grid>
        </form>
    )
}

Inputs.propTypes = {
    dataServices: PropTypes.array.isRequired,
};

export default Inputs
