import { Box, Grid, Typography, Button } from '@mui/material'
import React from 'react'
import imagen from '../img/tiburonballena.jpg'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import ButtonLang from './Usage/ButtonLang';

const FormsFinished = () => {

    const { t } = useTranslation();

    const uuid = uuidv4();

    const Navigate = useNavigate();
    const RouteNavigate = (path) => {
        Navigate(path);
    }

    const { id } = useParams();

    return (
        <Box
            component={'div'}
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                alignItems: 'center',
                height: '100vh'
            }}
        >
            <Grid
                container
                sx={{
                    gap: 2,
                    width: 800,
                    backgroundColor: 'white',
                    borderRadius: 2,
                    backgroundImage: `linear-gradient(to right, rgb(36, 57, 73, 0.8) 0%, rgb(81, 127, 164, 0.8) 100%), url(${imagen})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    padding: 2,
                }}
            >
                <Grid item xs={12}>
                    <ButtonLang />
                </Grid>

                <Typography variant='h4' sx={{ width: '100%', color: 'white', fontSize: { xs: 24, sm: 40 }, fontWeight: 'bold' }}> {t('finished')} </Typography>

                <Typography variant='h6' sx={{ width: '100%', color: 'white', fontSize: { xs: 18, sm: 20 } }}>
                    {id}
                </Typography>

                <Button variant='contained' color="inherit" onClick={() => RouteNavigate(`/formsregister/${uuid}`)} >
                    {t('finishedbutton')}
                </Button>

            </Grid>

        </Box>
    )
}

export default FormsFinished
