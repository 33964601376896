import React from 'react'
import { Grid, Typography } from '@mui/material';
import logo from '../../img/logo-white.svg'
import footerimage from '../../img/tiburonballena.jpg'
import { useTranslation } from 'react-i18next';

const InputsFooter = () => {
    const { t } = useTranslation();

    return (
        <Grid container
            sx={{
                padding: 2,
                backgroundImage: `linear-gradient(to right, rgb(36, 57, 73, 0.8) 0%, rgb(81, 127, 164, 0.8) 100%), url(${footerimage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            }}
        >
            <Grid item xs={12} sx={{ textAlign: 'center' }} >
                <Typography variant='h6' sx={{ color: 'white', fontWeight: 'bold' }} >
                    {t('finally')}
                </Typography>
            </Grid>

            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography variant='body2' sx={{ color: 'white', fontWeight: 'bold' }}  >
                    www.aquaworld.com.mx
                </Typography>
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <img src={logo}
                    style={{
                        width: '200px'
                    }}
                    alt='logo'
                />
            </Grid>

        </Grid>
    )
}

export default InputsFooter
