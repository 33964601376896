import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import image from '../img/landing.jpg'
import logo from '../img/logo-white.svg'
import ButtonLang from './Usage/ButtonLang';
import { useTranslation } from 'react-i18next';


const FormsWelcome = () => {
    const { t } = useTranslation();
    const uuid = uuidv4();

    const Navigate = useNavigate();
    const RouteNavigate = (path) => {
        Navigate(path);
    }

    // crea un nuevo objeto `Date`
    var today = new Date();

    // obtener la fecha y la hora
    var now = today.toLocaleString();


    return (
        <Box
            component={'div'}
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                alignItems: 'center',
                height: '100vh',
            }}
        >

            <Grid
                container
                sx={{
                    gap: 2,
                    width: 800,
                    backgroundColor: 'white',
                    borderRadius: 2,
                    padding: 2,
                    backgroundImage: `linear-gradient(to right, rgb(36, 57, 73, 0.8) 0%, rgb(81, 127, 164, 0.8) 100%), url(${image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
            >

                <Grid item xs={12}>
                    <ButtonLang />
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={logo}
                        style={{
                            width: '200px'
                        }}
                        alt='logo'
                    />
                </Grid>

                <Typography variant='h4' sx={{ width: '100%', color: 'white', fontSize: { xs: 24, sm: 40 }, fontWeight: 'bold' }}> {t('welcome')} </Typography>

                <Typography variant='h6' sx={{ width: '100%', color: 'white', fontSize: { xs: 18, sm: 20 } }}> {now} </Typography>

                <Button variant='contained' color="inherit" onClick={() => RouteNavigate(`/formsregister/${uuid}`)} >
                    {t('welcomebutton')}
                </Button>
            </Grid>

        </Box>
    )
}

export default FormsWelcome
